<template>
  <div>
    <Main style='margin-top: 2rem;'>
      <div v-if='questions'  class='form-answers'>
        <a-row>
          <a-col :xxl='24' :lg='24' :xs='24'>
            <sdCards class='form-answers__header'>
              <a-row :gutter='25' class='title'>{{questions.sections[0].title}}</a-row>
              <a-row :gutter='25' class='subtitle'>{{questions.sections[0].description}}</a-row>
            </sdCards>
          </a-col>
        </a-row>
        <a-row :gutter='25'>
          <a-col :xxl='24' :lg='24' :xs='24' v-for='(question, idx) in questions.sections[tabValue].questions' :key='question.id'>
            <sdCards class='question-card'>
              <a-form
                name='sDash_validation-form'
                ref='formRef'
                layout='vertical'
              >
                <question-render
                  :modelValue='answers[question.id].content'
                  :question='question'
                  :errors='errors'
                  @update:modelValue="handleUpdateValue(answers[question.id],$event, question)"
                  @update:cctmodelValue="searchCCT($event, question, tabValue, idx)"
                />
              </a-form>
            </sdCards>
          </a-col>
        </a-row>
        <a-row>
          <a-col :xs="24" v-if="sendFormLoading">
            <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;"/>
          </a-col>
          <div class='form__buttons' v-else>
            <button class='btn-outlined-exit' @click='handleCancel'>
              <span class='title-normal'> Cancelar </span>
            </button>
            <button class='btn-fill-add'
                    @click='handleValidateTypeQuestion(questions.sections[tabValue].questions, tabValue, false)'
                    v-if="tabValue > 0">
              <span class='title-normal'> Ir a la sección anterior </span>
            </button>
            <button class='btn-fill-add'
                    @click='handleValidateTypeQuestion(questions.sections[tabValue].questions, tabValue, true)'
                    v-if="tabValue < questions?.sections?.length - 1 && !endForm">
              <span class='title-normal'> Ir a la siguiente sección </span>
            </button>
            <button class='btn-fill-add' v-on:click='handleSubmit' v-else>
              <span class='title-normal'> Guardar Respuestas </span>
            </button>
          </div>
        </a-row>
      </div>
    </Main>
  </div>
</template>

<script>
import { Main } from '../styled';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { getDynamicFormAnswers, editAnswer } from '@/services/events';
import {getQuestionLevel3CCT} from '@/services/home';
import QuestionRender from '@/view/home/overview/QuestionRender';
import { Notification } from 'ant-design-vue';
import * as questionTypes from '@/constants/QuestionTypes.js';
import { validateDynamicFormSection } from '@/utility/utility';

export default {
  name: 'EditAnswers',
  components: {
    Main,
    QuestionRender,
  },
  data: () => {
    return {
      answers: null,
      questions: null,
      sendFormLoading: false,
      tabValue: 0,
      errors: {},
      timer: null,
      router: useRouter(),
      match: useRoute(),
      sectionsRoute: [0],
      endForm: false,
    };
  },
  methods: {
    handleUpdateValue(value, newValue, question){
      value.content = newValue
      if(value.type === 'multipleChoice' || value.type == "dropDown" && question.goto){
        const option = question.options.find(o=>o.id === value.content);
        if(option && option.goto === "finish"){
          this.endForm = true;
        }else{
          this.endForm = false;
        }
      }
    },
    handleValidateTypeQuestion(questions, sectionIndex, next){
      if (!this.validateSection()){
        return;
      }
      const steps = next ? 1 : -1;
      //boton anterior
      if (!next) {
        this.sectionsRoute.pop();
        this.handleChangeSection(this.sectionsRoute[this.sectionsRoute.length - 1], steps)
        return
      }
      let question = null
      for (let i = questions.length - 1; i >= 0; i--) {
        if (questions[i].type === 'multipleChoice' || questions[i].type === 'dropDown') {
          question = questions[i];
          break;
        }
      }
      const optionSelect = this.answers[question?.id]?.content
      if (question) {
        if (question.goto) {
          const option = question.options.find(item => item.id === optionSelect)
          if (option.goto === 'next') {
            this.handleChangeSection( sectionIndex + steps, steps)
          } else if (option.goto === 'finish') {
            this.handleChangeSection( this.questions.sections.length - 1, 1)
          } else {
            const indextoGo = this.questions.sections.indexOf(this.questions.sections.find(item => item.id === option.goto))
            if(indextoGo != this.tabValue){
              this.handleChangeSection( indextoGo, steps)
            }
          }

        } else {
          this.handleChangeSection( sectionIndex + steps, steps)
        }
      } else {
        this.handleChangeSection( sectionIndex + steps, steps)
      }
    },
    async handleChangeSection(nextSection, value){
      if (value == 1) {
        this.tabValue = nextSection
        this.sectionsRoute.push(nextSection)
      } else {
        this.tabValue = nextSection
      }
      console.log(this.sectionsRoute)
    },
    showNotificationAndRedirect: function (type, message, description) {
      Notification[type]({
        message,
        description,
      });
      setTimeout(() => {
        this.router.push('/');
      }, 2000);
    },
    getAnswers: async function() {
      const response = await getDynamicFormAnswers(this.match.params.id);
      if (response.success) {
        this.answers = response.data.answers_json;
        console.log(this.answers)
        for(let id in this.answers) {
          const answer = this.answers[id];
          if (answer.type == questionTypes.file && !answer.content)
            answer.content = [];
        }
        this.questions = response.data.questions_json;
      } else {
        this.showNotificationAndRedirect('error', 'Error', response.message || 'Oops ocurrió un error inesperado');
      }
    },
    async searchCCT(event, question, sectionIdx, questionIdx){
      if (this.timer){
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        //SendRequest
        if (!event) {
          //props.setCCTLoading(false);
          return;
        }
        const response = await getQuestionLevel3CCT(event)
        if (response.success && response?.data?.length) {
          const data =  response?.data ? response.data[0] || {} : {}

          for (let i = 0; i < question.cct.length; i++) {
            const fields = question.cct[i].split('.'); //Split fields by '.'
            let value = data;
            fields.forEach(field => {
              value = value[field];
            })
            let cctFillQuestion = this.questions.sections[sectionIdx].questions[questionIdx + i + 1];
            this.answers[cctFillQuestion.id].content = value;
          }
          this.errors[question.id] = '';
        } else {
          this.errors[question.id] = 'La clave ingresada no es correcta';
        }
      }, 2000);

      this.answers[question.id].content = event

    },
    async validateSection() {
      this.errors = await validateDynamicFormSection(this.questions.sections[this.tabValue], this.answers);
      for (let error in this.errors) {
        if (this.errors[error])
          return false;
      }
      return true;
    },
    async handleSubmit() {
      const valid = await this.validateSection()
      if (!valid)
        return;
      this.sendFormLoading = true;
      //console.log(this.answers)
      const form = new FormData( )
      this.formatData(form, {answers_json: this.answers});
      form.append('_method', 'put');
      this.sendFormLoading = true;
      const response = await editAnswer(this.match.params.id, form);
      if (response.success) {
        this.showNotificationAndRedirect('success', 'Éxito', response.message, response.data, this.eventID);
      } else {
        this.showNotificationAndRedirect('error', 'Error', response.message);
      }
    },
    formatData: function (form, data, prefix = '') {
      for (let key in data) {
        if ((typeof data[key] === 'object') && !(data[key] instanceof  File)) {
          this.formatData(form, data[key], `${prefix}${key}${prefix ? ']' : ''}[`);
        } else {
          if(typeof data[key] === 'boolean') data[key] = data[key] ? 1 : 0;
          form.append(`${prefix}${key}${prefix ? ']' : ''}`, data[key]);
        }
      }
    },
    handleCancel() {
      this.$router.go(-1);
    },
  },

  mounted() {
    const { dispatch } = useStore();
    dispatch('changeTitleHeader', 'Ver respuestas de registro');
    this.getAnswers();
  },
};
</script>
<style scoped lang='sass'>
.form-answers
  .form-answers__header
    padding-left: 21px
    .title
      color: #404040
      font-family: 'Nutmeg Bold'
      font-size: 18px
      margin-bottom: 18px
    .subtitle
      color: #7B868C
      font-family: 'Avenir Roman'
      font-size: 14px

button
  padding: 5px 15px 5px 15px
  border-radius: 24px

button:hover
  cursor: pointer

.btn
  border: 1px solid #9ED2EF
  background: #3FA7E1
  color: white

.form__buttons
  padding: 32px 21px

  width: 100%
  display: flex
  justify-content: center
  align-items: center
  gap: 15px

.btn-fill-add
  border: 1px solid #9ED2EF
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #3FA7E1
  border-radius: 24px

.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 24px
  margin-left: 2rem
</style>
